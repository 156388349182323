import { useEffect, useRef, useState } from "react";
// import chatIcon from "./assets/botIcon.png";
import logoCT from "./assets/logoCT.png";
import "./style.css";
function App() {
  const iframref = useRef(null);
  const BOT_URL = "https://celebal-bot.azurewebsites.net";
  // const BOT_URL = "http://localhost:3000";
  const [iframeKey, setIframeKey] = useState(0);
  const [urlPath, setUrlPath] = useState("");
  const [count, setCount] = useState(0);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [switchFrame, setSwitchFrame] = useState(false);
  const [switchClass, setSwitchClass] = useState(false);

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: "pageEventby",
  //   });
  // });
  var varHeight;

  if (urlPath.includes("/products")) {
    varHeight = "products_FloatHeight";
  } else if (urlPath.includes("/")) {
    varHeight = "home_FloatHeight";
  }

  function sendMsgToIframe(message) {
    console.log("sending message to bot");
    if (!iframref.current) {
      return;
    }
    iframref.current.contentWindow.postMessage(message, BOT_URL);
  }

  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event.origin === BOT_URL) {
        console.log(event.data, "<<<<<<-------");
        if (event.data === "switchFrameClass") {
          setSwitchClass(true);
          setIsChatOpen(true);
          setSwitchFrame(true);
        }
        console.log("data receieved");
      }
    });
  }, []);

  // checking if the url has changed
  const checkingURL = setInterval(() => {
    // console.log("calling setInterval");
    if (window.location.pathname !== urlPath)
      setUrlPath(window.location.pathname);
  }, 1000);

  // send url to the iframe window
  useEffect(() => {
    setIframeKey(iframeKey + 1);
    var url_address = window.location.href;
    sendMsgToIframe("closeFrame");
    if (switchClass) {
      setSwitchFrame(true);
    } else {
      setSwitchFrame(false);
    }
    setSwitchClass(false);
    setIsChatOpen(false);
    // sendDataLayer(window.dataLayer);
    setTimeout(() => {
      sendMsgToIframe(url_address);
    }, 3000);

    return () => {
      console.log("clearing interval");
      clearInterval(checkingURL);
    };
  }, [urlPath]);

  // set the Pathname when user change the URL
  useEffect(() => {
    setUrlPath(window.location.pathname);
  }, []);

  return (
    <div className="App">
      <iframe
        title="chatbot"
        className={`${
          switchFrame || switchClass
            ? "iframe_bot"
            : `${varHeight} iframe_float`
        }`}
        style={
          switchFrame && !isChatOpen
            ? {
                pointerEvents: "none",
                transition:
                  "transform 200ms ease 0s, opacity 200ms ease 0s, visibility 0ms linear 100ms",
                visibility: "hidden",
                transform: "translate(0px, 25px)",
              }
            : { visibility: "visible" }
        }
        ref={iframref}
        src={BOT_URL}
        scrolling="no"
        frameborder="0"
        key={iframeKey}
        id="target"
      />

      <div
        className={`botIcon-container ${isChatOpen && "anime"}`}
        onClick={() => {
          isChatOpen ? setIsChatOpen(false) : setIsChatOpen(true);
          sendMsgToIframe("openFrame");
          setSwitchFrame(true);
        }}>
        {!isChatOpen ? (
          <img src={logoCT} alt="chat icon" className="logoCT" />
        ) : (
          // <img src={closeIcon} alt="close icon" className="closeIcon" />
          <div className="closeIcon"></div>
        )}
      </div>
    </div>
  );
}

export default App;
